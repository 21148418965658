import * as PIXI from 'pixi.js'
import gsap from 'gsap'
import axios from 'axios';
import GIF from 'gif.js'
// import { alert } from '../utils/utils'

const frameInterval = 100;
const HTML = document.getElementsByTagName('html')[0]

class App {

  constructor(opts) {
    let _ = this
    _.opts = opts;

    this.loader = document.querySelector('.giforama__loader');

    this.options = {
      selector: '.panel',
      current: -1,
      liveview: {
        width: 800,
        height: 800
      },
      video: {
        width: 1920,
        height: 1080
      }
    }

    if (_.opts.format == 'portrait') {
      this.options.liveview = {
        width: 1080,
        height: 1620
      }
    }

    this.options.steps = document.querySelectorAll(this.options.selector).length

    this.init();

    // ---------------------
    // Create liveview panel
    // ---------------------
    this.panelLiveview()

    // // ---------------------
    // // Create preview panel
    // // ---------------------
    this.panelPreview()

    // ---------------------
    // Create email panel
    // ---------------------
    this.panelEmail()

    // // ---------------------
    // // Go on first panel
    // // ---------------------
    this.goto(0);
  }

  init() {
    let _ = this;

    if (_.opts.nbPhoto > 1) {
      _.gif = new GIF({
        workers: _.opts.nbPhoto,
        quality: 1,
        width: this.options.liveview.width,
        height: this.options.liveview.height,
        workerScript: '/static/js/gif.worker.js',
      })
      _.gif.on('finished', function (blob) {
        _.addPreview(blob).then(() => {
          // _.goto(_.options.current + 1)
        });
      });
    }

    _.media = {
      id: Date.now(),
      final: '',
      type: 'photo',
      event: event,
      saveOrig: _.opts.saveOrig,
      screen: [],
      render: [],
      infos: {
        message: '',
        email: ''
      }
    }

    // Clear form
    let inputs = document.querySelectorAll('input');
    if (inputs) {
      inputs.forEach((el) => {

        if (el.getAttribute('type') == 'checkbox') {
          el.checked = true;
        } else {
          el.value = '';
        }
      })
    }
  }

  createLiveview(elt) {
    let _ = this

    let ticker = PIXI.Ticker.shared;
    let link = document.querySelectorAll('a[disabled]');

    let wCanvas = this.options.liveview.width
    let hCanvas = this.options.liveview.height
    let wCamera = this.options.video.width
    let hCamera = this.options.video.height

    _.cLive = new PIXI.Application({ width: wCanvas, height: hCanvas });

    _.stage = new PIXI.Container();
    _.template = new PIXI.Container();
    _.cLive.stage.addChild(_.stage)
    _.cLive.stage.addChild(_.template)

    _.videoSprite = new PIXI.Sprite();
    _.stage.addChild(_.videoSprite);

    elt.append(_.cLive.view);

    let camera = document.createElement('video')
    camera.autoplay = true;
    camera.setAttribute('webkit-playsinline', '');
    camera.setAttribute('playsinline', '');
    camera.setAttribute('muted', '');

    navigator.mediaDevices.getUserMedia({
      audio: false,
      video: {
        width: { ideal: wCamera },
        height: { ideal: hCamera }
      }
    }).then(function (stream) {
      if (link) {
        link.forEach((el) => {
          el.removeAttribute('disabled')
        })

        document.querySelector('.notice-required').remove();
      }

      _.stream = stream
      camera.srcObject = _.stream
      camera.onloadedmetadata = () => {
        // camera.play();
        wCamera = camera.videoWidth;
        hCamera = camera.videoHeight;
        _.options.video.width = wCamera;
        _.options.video.height = hCamera;

        let texture = PIXI.Texture.from(camera);
        // let texture = PIXI.VideoBaseTexture.from(camera);

        let newW = wCamera > hCamera ? wCamera * hCanvas / hCamera : wCanvas;
        let newH = wCamera > hCamera ? hCanvas : hCamera * wCanvas / wCamera;

        _.videoSprite.texture = texture
        _.videoSprite.anchor.set(0.5);
        _.videoSprite.scale.x = -1
        _.videoSprite.x = wCanvas / 2
        _.videoSprite.y = hCanvas / 2;
        _.videoSprite.width = newW;
        _.videoSprite.height = newH;

        if (_.opts.gabarits) {
          let templates = _.opts.gabarits;

          // Add gabarit (habillage)
          let loader = new PIXI.Loader();
          for (var i = 0; i < templates.length; i++) {
            loader.add('gif' + i, templates[i])
          }
          loader.load((loader, resources) => {
            let frames = []
            for (var i = 0; i < _.opts.nbPhoto; i++) {
              frames.push(resources['gif' + i].texture);
            }

            const anim = new PIXI.AnimatedSprite(frames);
            anim.x = wCanvas / 2;
            anim.y = hCanvas / 2;
            anim.width = wCanvas;
            anim.height = hCanvas;
            anim.anchor.set(0.5);
            anim.animationSpeed = frameInterval / 1000;
            anim.play();

            _.template.addChild(anim);
          });
        }
      }
    });
  }

  addPreview(blob = '') {
    let _ = this;

    return new Promise((resolve, reject) => {
      let panel = document.querySelector('[data-panel="preview"]');

      if (blob != '') {
        var reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = function () {
          _.media.final = reader.result;
        }
      } else {
        _.media.final = _.media.render[0]
      }

      let img = panel.querySelector('.preview__img');

      let gif = document.createElement('img');
      gif.src = (blob == '') ? _.media.render[0] : URL.createObjectURL(blob);

      gif.onload = function () {
        img.append(gif);
        resolve()
      }

      // Dl button
      let dl = panel.querySelector('.trigger-dl');
      if (dl) {
        dl.setAttribute('href', _.media.screen[0])
      }
    })
  }

  goto(index, callback = null) {
    let _ = this
    let elts = document.querySelectorAll(this.options.selector);
    let currentElt = elts[this.options.current];
    let nextElt = elts[index];

    let dir = index > this.options.current ? 1 : -1;

    if (currentElt != undefined) {
      gsap.to(
        currentElt,
        {
          duration: 0.5,
          x: (-100 * dir) + '%',
          ease: "circ.inOut"
        }
      )
    }

    gsap.fromTo(
      nextElt,
      {
        autoAlpha: 1,
        x: (100 * dir) + '%'
      },
      {
        duration: 0.5,
        x: '0%',
        delay: 0.1,
        ease: "circ.inOut",
        onComplete: function () {
          if (typeof callback == 'function') {
            callback.call();
          }

          if (index == _.options.steps - 1) {
            _.finish();
          }
        }
      }
    )


    this.options.current = index;
  }

  finish() {
    let _ = this;

    _.media.render = [];

    let form = new FormData();
    form.append('media', JSON.stringify(_.media))

    axios({
      method: 'post',
      url: `./save.php`,
      data: form
    }).then((response) => {
      if (response.data) {
        if (!response.data.statut) {
          alert(response.data.msg);
        }
      }
      _.goto(0);
    }).then(() => {
      _.goto(0);
    }).catch(function (error) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        alert(error.response.data);
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        alert(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        alert('Error', error.message);
      }
    });
  }

  /** ================================================= */
  /** ================================================= */
  /** ================================================= */
  /** ====== PANELS ======= */
  /** ================================================= */
  /** ================================================= */
  /** ================================================= */

  panelLiveview() {
    let _ = this;
    let panel = document.querySelector('[data-panel="liveview"]');
    let countdown = panel.querySelector('.camera--countdown');
    let flash = document.querySelector('.giforama__flash')

    _.createLiveview(panel.querySelector('.canvas'))

    // Construct live view tl
    _.tlStart = gsap.timeline({
      paused: true, onComplete: function () {

        if (_.opts.nbPhoto > 1) {
          _.gif.render();
        }

        _.videoSprite.scale.x *= -1
        _.goto(_.options.current + 1, function () {
          gsap.set(panel, { clearProps: 'all' });

          // Clear all props
          _.tlStart.pause().seek(0)
        });
      }
    })

    _.tlStart
      .to(
        panel.querySelector('.action'),
        {
          duration: 0.3,
          autoAlpha: 0
        }, 'start'
      )
      .fromTo(
        countdown.querySelectorAll('span'),
        {
          marginTop: '-5%',
          autoAlpha: 0
        },
        {
          duration: 0.45,
          stagger: 1,
          marginTop: '0%',
          autoAlpha: 1
        }, 'start+=.2')
      .to(
        countdown.querySelectorAll('span'),
        {
          duration: 0.45,
          stagger: 1,
          marginTop: '5%',
          autoAlpha: 0
        }, 'start+=.65')
      .to(
        panel.querySelectorAll('.canvas, .content'),
        {
          duration: 0.3,
          autoAlpha: 0,
          onComplete: () => {
          }
        }, 'take-picture')
      .to(
        flash,
        {
          duration: 0.05,
          autoAlpha: 1,
          repeat: _.opts.nbPhoto > 1 ? _.opts.nbPhoto : 0,
          repeatDelay: frameInterval / 1000,
          onRepeat: () => {
            // img.src = _.cLive.renderer.plugins.extract.base64(_.stage, 'image/jpeg', 1.0)
            // On prend un screenshot du canvas
            // _.media.screen.push(_.cLive.renderer.view.toDataURL('image/png', 1.0))
            _.gif.addFrame(_.cLive.view, { delay: frameInterval, copy: true });
          },
          onComplete: () => {
            // On retourne le retour video pour avoir l'image droite
            _.videoSprite.scale.x *= -1
            // On prend un screenshot du canvas
            if (_.opts.nbPhoto <= 1) {
              _.media.screen.push(_.cLive.renderer.plugins.extract.base64(_.stage, 'image/png', 1))
              _.media.render.push(_.cLive.renderer.view.toDataURL('image/png', 1.0))
              _.addPreview();
            }
          }
        }, 'take-picture+=0.1'
      )
      .to(
        flash,
        {
          duration: 0.05,
          autoAlpha: 0,
        }, 'take-picture+=0.2'
      )

    // Start the itw
    panel.querySelector('.trigger-start').addEventListener('click', (e) => {
      e.preventDefault();
      let cTime = _.tlStart.time();
      clearTimeout(_.timerPause);
      if (cTime == 0 || cTime == _.tlStart.duration()) {
        _.tlStart.seek(0).play();
        _.media.id = Date.now();

        // Init default infos
        _.init()
      }
    })
  }

  panelPreview() {
    let _ = this;
    let panel = document.querySelector('[data-panel="preview"]');

    let btnRestart = panel.querySelector('.trigger-restart')
    let btnContinue = panel.querySelector('.trigger-continue')
    let imgContainer = panel.querySelector('.preview__img')

    // Restart 
    btnRestart.addEventListener('click', (e) => {
      e.preventDefault();

      // Go to first panel
      _.goto(0, reinitPanel);
    })

    // Continue 
    btnContinue.addEventListener('click', (e) => {
      e.preventDefault();

      // Go to first panel
      _.goto(_.options.current + 1, reinitPanel);
    })

    function reinitPanel() {
      imgContainer.innerHTML = '';
    }
  }

  panelEmail() {
    let _ = this;
    let panel = document.querySelector('[data-panel="email"]');

    let form = panel.querySelector('.form')

    form.addEventListener('submit', e => {
      e.preventDefault();
      var validEmail = /^[\w-]+(\.[\w-]+)*@([a-z0-9-]+(\.[a-z0-9-]+)*?\.[a-z]{2,6}|(\d{1,3}\.){3}\d{1,3})(:\d{4})?$/;
      let msg = panel.querySelector('.form-msg')

      msg.innerHTML = ''
      msg.classList.add('hidden')

      panel.querySelectorAll('input').forEach((elt) => {

        if (elt.hasAttribute('required') && elt.value == '') {
          msg.innerHTML = '<strong>ERREUR :</strong> Veuillez renseigner tous les champs.'
          msg.classList.remove('hidden')
          return;
        }
        else if (elt.hasAttribute('name') && elt.getAttribute('name') == 'email' && !validEmail.test(elt.value)) {
          msg.innerHTML = '<strong>ERREUR :</strong> Veuillez renseigner un email valide.'
          msg.classList.remove('hidden')
          return;
        }

        if (elt.getAttribute('type') == 'checkbox') {
          elt.value = elt.checked ? 1 : 0;
        }

        _.media.infos[elt.getAttribute('name')] = elt.value;
      })

      if (msg.classList.contains('hidden')) {
        _.goto(_.options.current + 1);
      }


      return false;
    })
  }

  destroy() {

  }
}

window.addEventListener('DOMContentLoaded', () => {
  new App(options);
})